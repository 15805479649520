
import { defineComponent, onMounted } from 'vue'
import linkGo from '@/hooks/links'

export default defineComponent({
  name: 'Recruit',
  setup(){
    onMounted(() => {
      _hmt.push(['_trackPageview', '/rectuit'])
    })
    return { linkGo }
  }
})
